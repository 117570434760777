import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import '../../styles/ant-style.less'
import '../../styles/layout.scss'

function SEO({ lang, meta, title, description }) {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          name: `keywords`,
          content: 'Quick API, csv, product, api, json, payment, make, money',
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:site_name`,
          content: 'Quick API',
        },
        {
          property: `og:url`,
          content: 'https://quickapi.io',
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          property: `twitter:card`,
          content: 'summary_large_image',
        },
        {
          property: `twitter:creator`,
          content: '@dot_louis',
        },
        {
          property: `twitter:site`,
          content: '@quickapi',
        },
      ].concat(meta)}
    >
      {process.env.VERCEL_ENV == 'production' && (
        <script defer src="/_vercel/insights/script.js"></script>
      )}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
}

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
